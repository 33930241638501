<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g
      id="Gruppe_7301"
      data-name="Gruppe 7301"
      transform="translate(-1445 -17)"
    >
      <circle
        id="Ellipse_290"
        data-name="Ellipse 290"
        cx="11"
        cy="11"
        r="11"
        transform="translate(1445 17)"
        fill="currentColor"
      />
      <path
        id="Pfad_8149"
        data-name="Pfad 8149"
        d="M12.727,6l-6,6L4,9.273"
        transform="translate(1448 19)"
        fill="none"
        stroke="currentStroke"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>
